/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

const LandingPage = () => {
  const [currentPage, setCurrentPage] = useState('nihil');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [showBreathingReminder, setShowBreathingReminder] = useState(false);

  const changePage = (newPage) => {
    setIsTransitioning(true);
    setShowBreathingReminder(true);

    // Fade out current content
    setTimeout(() => {
      setCurrentPage(newPage);
      // Start fading in new content
      setTimeout(() => {
        setShowBreathingReminder(false);
        setIsTransitioning(false);
      }, 600); // Second half of the transition for fade-in
    }, 600); // First half of the transition for fade-out
  };

  const renderPageContent = () => {
    switch (currentPage) {
      case 'nihil':
        return (
          <>
            <h1 className="text-6xl font-extralight mb-6 tracking-tight">nihil</h1>
            <p className="text-xl text-gray-600 mb-12 font-light max-w-2xl mx-auto leading-relaxed">
              Embrace the power of simplicity. One tool, one purpose.
              Eliminate decision fatigue and focus on what truly matters.
            </p>
            <div
              className="inline-flex items-center text-lg text-gray-900 font-medium hover:text-gray-600 transition duration-300 cursor-pointer"
            >
              Experience Nihil
            </div>
            <div className="mt-6 flex justify-center space-x-12">
              <div className="flex justify-center space-x-24">
                <Tool name="notes" icon="✎" />
                <Tool name="timer" icon="⏱" comingSoon />
                <Tool name="habit" icon="☑" comingSoon />
              </div>
            </div>
          </>
        );
      case 'philosophy':
        return (
          <>
            <h2 className="text-4xl font-extralight mb-6 text-left">philosophy</h2>
            <p className="text-xl text-gray-600 mb-8 font-light max-w-2xl mx-auto leading-relaxed text-left">
              At Nihil, I believe in the power of focused simplicity. The philosophy is rooted in the idea that by reducing complexity and eliminating unnecessary choices, we can achieve greater clarity and productivity in our daily lives.
            </p>
            <p className="text-xl text-gray-600 mb-8 font-light max-w-2xl mx-auto leading-relaxed text-left">
              I strive to create tools that embody this philosophy, enabling users to focus on what truly matters without the distraction of superfluous features or decisions.
            </p>
            <p className="text-xl text-gray-600 mb-8 font-light max-w-2xl mx-auto leading-relaxed text-left">
            I am also a strong believer in <a href="https://betterhumans.pub/in-praise-of-single-purpose-devices-5f35267902d4">single-purpose devices</a> (and tools) as a mean of combatting attention-hijacking of the modern era.
            </p>
          </>
        );
      case 'faq':
        return (
          <>
            <h2 className="text-4xl font-extralight mb-8 text-left">faq</h2>
            <div className="text-left max-w-2xl mx-auto space-y-6">
              <div>
                <h3 className="text-2xl font-light mb-2">What is Nihil?</h3>
                <p className="text-gray-600">Nihil is a curated collection of minimalist tools I've designed to enhance focus and productivity.</p>
              </div>
              <div>
                <h3 className="text-2xl font-light mb-2">How does Nihil work?</h3>
                <p className="text-gray-600">Each Nihil tool is purpose-built, stripping away complexity to offer a streamlined, distraction-free experience.</p>
              </div>
              <div>
                <h3 className="text-2xl font-light mb-2">Why have I built it?</h3>
                <p className="text-gray-600">I created Nihil out of a personal need for clutter-free, purpose-driven tools. My goal is to help others find clarity and focus in their digital lives, just as I sought for myself.</p>
              </div>
              <div>
                <h3 className="text-2xl font-light mb-2">Is Nihil free?</h3>
                <p className="text-gray-600">The web version of Nihil is free. To support ongoing development and hosting, I will be offering a mobile app version for a one-time fee (soon).</p>
              </div>
              <div>
                <h3 className="text-2xl font-light mb-2">How secure is my data?</h3>
                <p className="text-gray-600">I've made it a top priority to respect everyone's privacy even to the extent that Nihil does not collect any personal data in the authentication process, making it set itself apart from other solutions.</p>
              </div>
            </div>
          </>
        );
      case 'contact':
        return (
          <>
            <h2 className="text-4xl font-extralight mb-6 text-left">contact</h2>
            <p className="text-xl text-gray-600 mb-8 font-light max-w-2xl mx-auto leading-relaxed text-left">
              I'd love to hear from you. Whether you have questions, feedback, or just want to say hello, feel free to reach out.
            </p>
            <a href="mailto:hello@getnihil.com" className="text-2xl text-gray-900 hover:text-gray-600 transition duration-300">hello@getnihil.com</a>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-white text-gray-900 min-h-screen flex flex-col md:flex-row">
      <nav className="w-full md:w-auto py-4 md:py-0 md:fixed md:left-8 md:top-1/2 md:transform md:-translate-y-1/2 flex md:flex-col justify-center md:justify-start items-center md:items-start space-x-4 md:space-x-0 md:space-y-4 text-sm font-light z-20">
        <a onClick={() => changePage('nihil')} className="text-gray-600 hover:text-gray-900 transition duration-300 cursor-pointer">nihil</a>
        <a onClick={() => changePage('philosophy')} className="text-gray-600 hover:text-gray-900 transition duration-300 cursor-pointer">philosophy</a>
        <a onClick={() => changePage('faq')} className="text-gray-600 hover:text-gray-900 transition duration-300 cursor-pointer">faq</a>
        <a onClick={() => changePage('contact')} className="text-gray-600 hover:text-gray-900 transition duration-300 cursor-pointer">contact</a>
      </nav>

      <div className="flex-grow flex items-center justify-center relative">
        <div className={`max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center transition-opacity duration-600 ease-in-out ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}>
          {renderPageContent()}
        </div>
        {showBreathingReminder && (
          <div className="absolute inset-0 flex items-center justify-center z-10">
            <p className="text-2xl font-light text-gray-600 transition-opacity duration-300 ease-in-out">
              remember to breathe
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const Tool = ({ name, icon, comingSoon }) => (
  <a
    href={comingSoon ? "#" : "https://notes.getnihil.com/"}
    className={`text-center group cursor-pointer relative block ${comingSoon ? 'pointer-events-none' : ''}`}
    onClick={(e) => comingSoon && e.preventDefault()}
  >
    <div className={`text-3xl mb-2 ${comingSoon ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-600'} transition-colors duration-300`}>
      {icon}
    </div>
    <div className={`font-light text-lg ${comingSoon ? 'text-gray-300' : 'text-gray-800 group-hover:text-gray-600'} transition-colors duration-300 relative inline-block`}>
      {name}
      {!comingSoon && (
        <span className="absolute bottom-0 left-0 w-0 h-px bg-gray-600 transition-all duration-300 group-hover:w-full"></span>
      )}
    </div>
    {comingSoon && (
      <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-1 bg-gray-800 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        Coming Soon
      </div>
    )}
  </a>
);

export default LandingPage;